/* General styles for the app container */
.App {
  text-align: center;
  background-color: #F1F4EE;
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.truckInHeader {
  height: 100%;
}

/* Header */
.App-header {
  background-color: #3C5A69;
  max-height: 15vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  padding: 0 5%;
}

/* Main content area */
.main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  padding: 2vmin;
}

/* Responsive main text area */
.maintextarea {
  font-size: clamp(20px, 4vw, 35px);
  font-weight: bold;
}

/* Main image styling */
.main-img {
  max-width: 85%;
  height: auto;
  object-fit: cover;
}

/* Smaller text styling */
.main-smaller-text {
  font-size: clamp(16px, 2vw, 22px);
  font-weight: lighter;
  margin-top: 1rem;
}

/* Divider styling */
.dividerContainer {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
}

.divider {
  background-color: #3C5A69;  
  height: 2px;
  width: 80%;
}

/* Contact section */
.contactContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  font-size: clamp(12px, 2vw, 18px);
}

/* Responsive adjustments */
@media only screen and (max-width: 750px) {
  .main {
    grid-template-columns: 1fr;
    padding: 1rem; /* Reduced padding for mobile */
  }

  .maintextarea, .main-smaller-text {
    max-width: 100%;
  }

  .dividerContainer {
    margin: 1rem 0; /* Reduced margin for mobile */
  }

  .contactContainer {
    padding: 1rem; /* Reduced padding for mobile */
  }
}

@media only screen and (max-width: 500px) {
  .App-header {
    padding: 0 2%;
  }

  .main-img {
    max-width: 100%; /* Allow the image to take full width on smaller screens */
  }

  .maintextarea {
    margin-top: 1rem; /* Add a slight margin to ensure spacing is consistent */
  }

  .contactContainer {
    padding: 0.5rem; /* Further reduce padding on smaller screens */
  }
}
